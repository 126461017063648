import { useEffect, useMemo, useState } from 'react';
import './mainBanner.css';

let autoScrollTimer;
/**
 * @param {IProps} props component props
 * @returns {ReactElement}
 */
const MainBanner = (props) => {
    const {
        list,
        width
    } = props;
    const [isResetInProgress, setIsResetInProgress] = useState(false);
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const [transitionProperty, setTransitionProperty] = useState('left');
    const [isMouseEnter, setIsMouseEnter] = useState(false);
    const [flag, setFlag] = useState(false); // 手动切换
    const bannerScrollLeftPercent = useMemo(() => (
        `${currentBannerIndex * 100 * -1}%`
    ), [currentBannerIndex, list]);
    const offsetLeft = useMemo(() => (
        `${list.length * 100 * -1}%`
    ), [list]);

    useEffect(() => {
        if (isResetInProgress) {
            setTransitionProperty('left');
            setIsResetInProgress(false);
        }
    }, [isResetInProgress]);

    useEffect(() => {
        if (currentBannerIndex >= list.length + 1 && list.length > 0) {
            setCurrentBannerIndex(0);
            setTransitionProperty('left');
        }
        clearInterval(autoScrollTimer);
        if (list.length <= 1) {
            return;
        }
        autoScrollTimer = window.setInterval(() => {
            if (isMouseEnter) {
                return;
            }
            setTransitionProperty('left');
            if (currentBannerIndex < list.length) {
                setCurrentBannerIndex(currentBannerIndex + 1);

            }
        },3000);
        return () => {
            clearInterval(autoScrollTimer);
        };
    }, [list, currentBannerIndex, isMouseEnter]);

    const bannerItemList = useMemo(() => {
        const _list = [...list, ...list, ...list];
        const filteredComponents = _list.map((item, index) => {
            const _item = item;
            const childrenProps = {
                className: 'site-main-banner-content-item',
                key: `${index}${_item.url}`,
            };
            return  (
                <div {...childrenProps}>
                    <img src={_item.url}  className="site-img" style={{width: width}} alt=""/>
                    {/* <video 
                src={_item.url} 
                autoPlay="autoplay"
                className="site-video"
                muted 
                loop 
                style={{ 
                  height: this.state.clientHeight + "px",
                  width: this.state.clientWidth + "px",
                  marginLeft: -this.state.marginLeft + "px", 
                  marginTop: -this.state.marginTop + "px" }}
                /> */}
                </div>
            );
        });

        return filteredComponents;
    }, [list]);

    const next= () => {
        if (currentBannerIndex < list.length) {
            setFlag(true);
            clearInterval(autoScrollTimer);
            setCurrentBannerIndex(currentBannerIndex + 1);
        }
    }
    const pre = () => {
        if (currentBannerIndex > 0) {
            setFlag(true);
            clearInterval(autoScrollTimer);
            setCurrentBannerIndex(currentBannerIndex - 1);
            }
    }
    useEffect(() => {
        setTimeout(() =>{
            setFlag(false);
        }, 3000)
    }, [currentBannerIndex]);
    return (
        <div className="site-main-banner">
            <div className="site-main-banner-offset" style={{ left: offsetLeft }}>
                <div
                    className="site-main-banner-content-scroller"
                    style={{
                        left: bannerScrollLeftPercent,
                        transition: (currentBannerIndex || flag) ? `${transitionProperty} 1s ease-in-out` : ''
                    }}
                    onMouseOver={() => {
                        setIsMouseEnter(true);
                    }}
                    onMouseOut={() => {
                        setIsMouseEnter(false);
                        clearInterval(autoScrollTimer);
                    }}
                    onTransitionEnd={() => {
                        if (currentBannerIndex < 0) {
                            setCurrentBannerIndex(list.length - 1);
                            setTransitionProperty('none');
                            setIsResetInProgress(true);
                            return;
                        }
                        if (currentBannerIndex >= list.length) {
                            setCurrentBannerIndex(0);
                            setTransitionProperty('none');
                            setIsResetInProgress(true);
                        }
                    }}
                >
                    {bannerItemList}
                </div>
            </div>
            {/* 左右控制台 */}
           {
                list.length > 1 && 
                <div className="site-controller">
                {currentBannerIndex !== 0 &&  <div className="site-controller-pre" onClick={pre}></div>}
                    {currentBannerIndex < list.length && 
                    <div className="site-controller-next" onClick={next}></div>}
                </div>
            }
        </div>
    );
};

export default MainBanner;
