import styles from './banner.less';
import LeftSide from './leftSide/LeftSide';
import RightSide from './rightSide/RightSide';
import man1 from './img/Digital man1.png';
import man2 from './img/Digital man2.png';
import man3 from './img/Digital man3.png'
import modeling1 from './img/modeling1.png';
import modeling2 from './img/modeling2.png'
import modeling3 from './img/modeling3.png'
import video0 from './img/video0.mp4'
import video1 from './img/video1.mp4'
import video2 from './img/video2.mp4'
const Banner = () => {
    const leftList = [
        { url: man1 },
        { url: man2 },
        { url: man3 }
    ];
    const rightList = [
        // { url: video0 },
        // { url: video1 },
        // { url: video2 },
        { url: modeling3 }
    ];
    return (
        <div className={styles.siteBanner}>
            <LeftSide list={leftList} />
            <div className={styles.bannerTop}>
                <RightSide list={rightList} />
            </div>
        </div>
    )
};

export default Banner;