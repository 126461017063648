import ContactUs from '@pages/home/contactUs/contactUs.jsx';
import PageFooter from '@pages/home/pageFooter/pageFooter.jsx';
import CompanyProfile from '@pages/home/companyProfile/companyProfile.jsx';
import Services from '@pages/home/services/Services.jsx';
import FirstScreen from '@pages/home/firstScreen/FirstScreen.jsx';
import IntroductionInfo from '@pages/home/introductionInfo/IntroductionInfo.jsx';
import Banner from '@pages/home/banner/Banner.jsx';
import HeaderBar from '@pages/common/headerBar/HeaderBar.jsx';
import { useEffect, useState } from 'react';

import './home.css';

function Home() {
  const [clientHeight, setClientHeight] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  
  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop;
    setScrollTop(scrollTop);  
  }

  const handleResize = () => {
    setClientHeight(document.documentElement.clientHeight || window.innerHeight);  
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    setClientHeight(document.documentElement.clientHeight || window.innerHeight);  
    return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
    };
}, []);
  console.log('scrollTop----', scrollTop, clientHeight)
  return (
    <div className="site-hom">
      {scrollTop >= clientHeight && <HeaderBar/>}
      <FirstScreen/>
      <IntroductionInfo />
      <Banner />
      <Services />
      <CompanyProfile/>
      <ContactUs/>
      <PageFooter/>
    </div>
  );
}

export default Home;
