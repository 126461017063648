import styles from './leftSide.less';
import Banner from '@pages/common/minBanner/minBanner.jsx';

const LeftSide = (props) => {
    const { list } = props;
    return (
        <div className={styles.siteBannerLeft}>
            <div className={styles.banner}>
                <Banner list={list} />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    Digital Human
                    <div className={styles.titleCN}>数字人</div>
                </div>
                <div className={styles.desc}>
                     数回科技将你的形象和想象数字化，让超写实数字人服务你的项目。
                    <div style={{paddingTop: '15px'}}>
                    智能驱动数字人崭露头角；智能化、便捷化、精细化、多样化；所有技术模块化存储；媒体/载体从单一视觉体验变成多维度的体验。
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LeftSide;