import styles from './introductionInfo.less';

const IntroductionInfo = () => {

    return (
        <div className={styles.siteIntroductionInfo}>
            <div className={styles.infoBox}>
            <div className={styles.siteTitle}>数回科技让“实现”更简单</div>
            <div className={styles.siteDesc}>
                在当今数据量爆炸的情况下，科技的高速发展带来了想象力的快速变化。
                这使得以前不可能实现的事情现在成为了可能，以前难以实现的技术也变得简单。
                <div style={{paddingTop: '15px'}}>
                    不同行业对相关数据的呈现和应用有着不同的需求。
                    基于这些需求，数回科技提供软硬件一体的解决方案，
                    利用最新前沿的技术为行业提供加速服务，并为您提供全流程的项目服务，
                    以寻找最优解。
                </div>
            </div>
            </div>
        </div>
    )
};

export default IntroductionInfo;