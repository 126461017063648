import  styles from './pageFooter.less';
import logo from './logo.png';
/**
 * 页面底部
 * @return {JSX.Element}
 */
const PageFooter = () => {
   return (
        <div className={styles.pageFooter}>
            <div>© digitalcircle. 2023</div>
            <a style={{ color: '#fff', textDecoration: 'auto'}}
                href="https://beian.miit.gov.cn/" 
                target="_blank">
                <img src={logo} alt="" className={styles.logo}/>
                苏ICP备2023009355号-1 
            </a>
        </div>
    );
};

export default PageFooter;
