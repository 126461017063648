
import styles from './companyProfile.less'
import technical from './technology.png'
import project from  './project.png'
import law from './law.png'
import { useState } from 'react';

const list = [
    {
        title: '技术总监',
        dest: 'Technical Director',
        content: {
            img: technical,
            details: '从业经验十余年，南京大学业界导师（《数字人虚拟创作》工作坊），国家药检所材标准数字中药材提供方，国家级认定项目3个，省级认定项目3个，农、工业实训仿真系统23个，工作领域：高精度数字人、虚拟制片VP、数字化三维重建、裸眼3D视觉解决方案'
        }
    },
    {
        title: '项目经理',
        dest: 'Project Manager',
        content: {
            details: '项目经理PMP认证，南京艺术学院技术导师，整体可视化解决方案提供方，与索尼、宏碁、英伟达、LG等一流硬件品牌合作，提供从业务需求到展示场景的全链路设计服务，满足各种使用场景下的的展示要求。项目经历：政府裸眼3D展厅项目，政府市监局智慧监管系统，省级博物馆数字文化交互展厅项目，智能工厂系统，农业全息展示系统，艺术展览预演与评测虚拟仿真实验。',
            img: project
        }
    },
    {
        title: '合规团队',
        dest: 'Compliance Team',
        content: {
            img: law,
            details:'美国硅谷虚拟现实从业经验，中美（纽约州）两国律师执业资格，跨境隐私Certificated International Privacy Professional（US）证书，研究领域：数字版权、数字藏品、AI、数字人、NFT、区块链、开源代码运营模式。'
        }
    }
]
const CompanyProfile = () => {
    const [selected, setSelected] = useState('技术总监');

    const title = (item) => {
        return (
            <div className={styles.itemTitle}
                style={{borderBottom: item.title === selected ? '1px solid #311EA6' : ''}}>
                <div className={styles.titleLeft}>
                    <span style={{borderBottom: item.title === selected ? '1px solid #FFCC49' : ''}}>{item.title}</span>
                </div>
                <div>{item.dest}</div>
            </div>
        )
    }
    const handleSelect = (item) => {
        if (item) {
            setSelected(item.title !== selected ? item.title : '')
        }
    }
    return (
        <div className={styles.companyProfile}>
            <div style={{color: '#FFCC49', fontSize: '24px', paddingLeft: '40px', lineHeight: '32px'}}>Our Gene</div>
            <div className={styles.title}>
                我们的基因
            </div>
            {
                list.map((item, index) => (
                    <div key={index}
                        onClick={() => handleSelect(item)}>
                        {title(item)}
                        {
                            selected === item.title &&
                            <div className={styles.itemContent}>
                                {
                                    // item.content.img && 
                                    // <div className={styles.itemImg}>
                                    //     <img src={item.content.img} style={{verticalAlign: 'inherit'}} alt=""/>
                                    // </div>
                                }
                                {
                                    item.content.describe && 
                                    <div className={styles.itemLeft}>{item.content.describe}</div>
                                }
                                <div className={styles.content}>
                                    <div>{item.content.details}</div>
                                </div>
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default CompanyProfile;