import styles from './services.less';

import left from './img/Group1 (1).png';
import right from './img/Group1 (2).png';
const Services = () => {

    return (
        <div className={styles.siteServices}>
            <div className={styles.servicesTitle}>Services</div>
            <div className={styles.servicesCN}>服务</div>
           <div className={styles.servicesBox}>
                <div className={styles.boxLeft}>
                    <img src={left} alt=""  className={styles.boxImg}/>
                    <div className={styles.boxInfo}>
                        提供特型硬件+展示内容
                        <div>全流程解决方案</div>
                    </div>
                </div>
                <div className={styles.boxRight}>
                    <img src={right} alt="" className={styles.boxImg}/>
                    <div className={styles.boxInfo}>
                        为企业、学校提供数字人创作、虚拟拍摄和地形编辑等
                        <div>课程教学解决方案</div>
                    </div>
                </div>
           </div>
        </div>
    )
};

export default Services;