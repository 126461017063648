import styles from './rightSide.less';
import Banner from '@pages/common/minBanner/minBanner.jsx';

const RightSide = (props) => {
    const { list } = props;

    return (
        <div className={styles.siteBannerRight}>
            <div className={styles.content}>
                <div className={styles.title}>
                    3D Scanning
                    <div className={styles.titleCN}>扫描建模</div>
                </div>
                <div className={styles.desc}>
                    基于高度还原的摄影测量技术，对形体、纹理和色彩等物理信息进行数字化，模型符合科研、工业、游戏和影视标准。
                    {/* <div className={styles.descOpacity}>
                    微距三维扫描技术构建的中药材数字资产库，共采集中草药高精度资产2000+件，包含极其稀有的中草药数百种，满足《中国药典》标准，支撑中药行业构建数字化标准，促进中医药数字教育虚拟仿真建设和人才培养。
                    </div> */}
                </div>
            </div>
            <div className={styles.banner}>
                <Banner list={list} />
            </div>
        </div>
    )
};

export default RightSide;