  import { Component } from "react";
  import videoUrl from './video.mp4';
  import logoUrl from './logo.png';
  import './firstScreen.css';
  
  class FirstScreen extends Component {
    state = {
      clientHeight: 0,
      clientWidth: 0,
      marginLeft: 0,
      marginTop: 0,
      flag: true,
      top: -1,
    }
    componentDidMount() {
      this.resizeImage()
      let that = this
      window.onresize = function () {
        that.resizeImage()
      }
    }
    resizeImage() {
      let percent = document.documentElement.clientWidth / document.documentElement.clientHeight;
      if (percent < 1.77778) {
        this.setState({
          flag: true,
          clientWidth: (1080 / document.documentElement.clientHeight) * 1920,
          clientHeight: document.documentElement.clientHeight,
          marginLeft: ((1080 / document.documentElement.clientHeight) * 1920 - document.documentElement.clientWidth) / 2
        })
      } else {
        this.setState({
          flag: false,
          clientWidth: document.documentElement.clientWidth,
          clientHeight: (document.documentElement.clientWidth / 1920) * 1080,
          marginTop: ((document.documentElement.clientWidth / 1920) * 1080 - document.documentElement.clientHeight) / 2,
          marginLeft: 0,
        })
      }
      this.setState({
        top: document.documentElement.clientHeight * 0.6 * -1,
      })
    }
    render = () => {
      const text = () => (
       <div className="site-text">
          <img className="text-left" src={logoUrl} alt="logo" />
        <div className="text-right">Tech is a circle back always makes things easier.</div>
       </div>
      );
      return (
          <div className="site-first-screen">
            <video 
                src={videoUrl} 
                autoPlay="autoplay"
                className="site-video"
                muted 
                loop 
                style={{ 
                  height: this.state.clientHeight + "px",
                  width: this.state.clientWidth + "px",
                  marginLeft: -this.state.marginLeft + "px", 
                  marginTop: -this.state.marginTop + "px" }}
                />
            {text()}
          </div>
      );
    }
  }
  
  export default FirstScreen;
  