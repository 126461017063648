import { useEffect, useState } from 'react';

import logoUrl from './logo.png';
import styles from './headerBar.less';

const HeaderBar = () => {
    const [scrollTop, setScrollTop] = useState(0);
  
    const handleScroll = (e) => {
            const scrollTop = e.srcElement.scrollingElement.scrollTop;
            setScrollTop(scrollTop);
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={styles.headerBar}
            style={{
                height: scrollTop ? '56px': '80px', 
                lineHeight:  scrollTop ? '56px': '80px',
                background: scrollTop ? '#000' : 'transparent', 
                transition: scrollTop? 'all 2s' : 'all .5s'
            }}>
            <img src={logoUrl} alt="logo" />
        </div>
    )
};

export default HeaderBar;