import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import  styles from './contactUs.less';

import addressImg from './img/address.png';
import send from './img//send.png';
import location from './img/location.png';
import phone from './img/phone.png';
const address = 'https://map.baidu.com/search/%E5%8D%97%E4%BA%AC%E5%B8%82/@13225169,3748907.75,12z?querytype=s&da_src=shareurl&wd=%E5%8D%97%E4%BA%AC%E5%B8%82&c=289&src=0&wd2=%E6%B1%9F%E8%8B%8F%E7%9C%81%E5%8D%97%E4%BA%AC%E5%B8%82&pn=0&sug=1&l=12&b=(13464961.31,3610394.64;13581569.31,3671834.64)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=73d80fbb9cf7cc6a1d1d11e7&device_ratio=1'

// 飞书机器人 
const url = 'https://open.feishu.cn/open-apis/bot/v2/hook/f9dd5ed9-6961-4be5-a2e1-be3ed9543e88';
/**
 * 支持留言与我们联系
 * @return {JSX.Element}
 */
const ContactUs = () => {
    const boxRef = useRef(null);
    const [clientWidth, setClientWidth] = useState(0);
    const [info, setInfo] = useState(''); // 留言的信息
    const [name, setName] = useState(''); // 姓名
    const [telephoneOrEmail, setTelephoneOrEmail] = useState(''); // 电话或邮箱
    const [flag, setFlag] = useState(false);
    const handleSubmit = async () => {
        if (info && name && telephoneOrEmail) {
            let text ={  "text": '姓名：'+ name + ' 联系方式：' + telephoneOrEmail + ( info ? ' 留言信息: ' + info : '')};
            const params = {
                receive_id: '542b8fc7-698d-4f9e-9885-6cc3a466531c',
                "msg_type": "text",
                "content":  JSON.stringify(text)
            };
            axios({
                method: 'post',
                url: url,
                params,
            }).then(response => {
            if (response.data.StatusMessage) {
                setName('');
                setTelephoneOrEmail('');
                setInfo('');
                setFlag(true);
            }
            });
        }
    };

    const left = () => (
        <div >
            <div className={styles.messageMode}>
                <input 
                    style={{width: '30%'}}
                    className={styles.itemInput}
                    placeholder='您的姓名'
                    value={name} 
                    onChange= {(e) => setName(e.target.value)}
                />
                <input
                    style={{ width: '50%' }}
                    className={styles.itemInput}
                    placeholder='您的电话或邮箱'
                    value={telephoneOrEmail}
                    onChange= {(e) => setTelephoneOrEmail(e.target.value)}
                    />
            </div>
            <div className={styles.textDiv}>
                <textarea 
                    className={styles.inputInfo}
                    type='text' 
                    value={info} 
                    onChange= {(e) => setInfo(e.target.value)}
                    maxLength={200} 
                    placeholder={'给我们留言'}>
                </textarea>
                {flag && <div style={{color: '#fff', marginTop: '5px', fontSize: '14px'}}>留言已经提交啦~</div>}
                <span className={styles.textLength} 
                    style={{color: info.length === 200 ? 'red': '', 
                            opacity: info.length === 200 ? '1': '',
                            borderColor: info.length === 200 ? 'rgb(255,255,255, 0.2)' : ''
                        }}>
                        {info.length} / 200
                    </span>
                <div className={styles.inputBtn} onClick={ handleSubmit}>提交</div>
            </div>
        </div>
    )
    const middle =  () => (
        <>
            <div style={{cursor: 'pointer'}}>
                <img src={send} alt="" style={{paddingRight: '13px'}}/>
                邮件
                <div className={styles.info}>hi@digitalcircle.cn</div>
            </div>
            <div>
             <img src={phone} alt="" style={{paddingRight: '13px'}} />
                电话
                <div className={styles.info}>
                    <span style={{color: '#777'}}>+86 </span>
                    159 5200 3677
                </div>
            </div>
        </>
    );
    const right = () => (
        <div>
            <div className={styles.addressBox}>
                <div>
                    <img src={location} alt="" style={{paddingRight: '13px'}} />
                    地址
                </div>
                <div style={{paddingLeft: '52px'}}>江苏省南京市建邺区江东中路315号中泰国际广场6幢1528室</div>
                <div style={{paddingLeft: '52px'}}>1528, Block 6, Zhongtai International Plaz.
                    <div>No. 315 Jiangdong Middle Rd., JianYe Dist.</div>
                    <div>Nanjing, Jiangsu, PRC.</div>
                </div>
                <a href={address} target="_blank" style={{color: '#E8F0F9', paddingLeft: '52px'}}>
                    <div className={styles.addressImg}> 
                        <img className={styles.adImg} src={addressImg} alt=""></img>
                    </div>
                </a>
            </div>
        </div>
    )

    const handleResize = () => {
        setClientWidth(document.documentElement.clientWidth || window.innerWidth)  
    }
    
    useEffect(() => {
        if (flag) {
            setTimeout(() => {
             setFlag(false);
            }, 2000) 
        }
    }, [flag]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        setClientWidth(document.documentElement.clientWidth || window.innerWidth)  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log('clientWidth',boxRef.current, clientWidth);
   return (
        <div className={styles.contactUs} ref={boxRef}>
            <span style={{color: '#FFCC49', fontSize: '24px', paddingLeft: '40px', lineHeight: '32px'}}>Contact Us</span>
            <div className={styles.title}>
                联系我们
            </div>
            <div className={styles.line}></div>
            {
                clientWidth >= 992 && 
                <div className={styles.contentLayout}>
                   <div className={styles.leaveMessage}>{left()}</div> 
                   <div 
                        className={styles.contactInfo}
                        onClick={() => window.location.href = "mailto:user@example.com?subject=Subject&body=message%20goes%20here"}
                    >
                    {middle()}
                    </div>
                   <div  className={styles.address}>{right()}</div>
                </div>
            }
            {
              clientWidth < 992 && 
              <div className={styles.contentLayoutMedia}>
                    {left()}
                    <div
                        className={styles.middleMedia}
                        onClick={() => window.location.href = "mailto:user@example.com?subject=Subject&body=message%20goes%20here"}>
                        {middle()}
                    </div>
                    {right()}
              </div>  
            }
           
        </div>
    );
};

export default ContactUs;
